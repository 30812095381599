/*
Author: Nishant Singh
Description: Every reducer name should be name followed by suffix Reducer with camelCase.
*/
import { createReducer } from '@reduxjs/toolkit';
import { SET_VOUCHER, RESET_VOUCHER } from '../actions/voucher';
// ........States
const initialState = {
  categoryVouchers: [],
  voucherDetail: null,
  status: {
    categoryVouchers: 'idle',
    voucherDetail: 'idle',
  },
};

const voucherReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(`cart/categoryVouchers/pending`, (state) => {
      state.status.categoryVouchers = 'loading';
    })
    .addCase(`cart/categoryVouchers/fulfilled`, (state, action) => {
      state.categoryVouchers = action.payload;
      state.status.categoryVouchers = 'succeeded';
    })
    .addCase(`${SET_VOUCHER}/pending`, (state) => {
      state.status.voucherDetail = 'pending';
    })
    .addCase(`${SET_VOUCHER}/fulfilled`, (state, action) => {
      state.voucherDetail = action.payload;
      state.status.voucherDetail = 'succeeded';
    })
    .addCase(`${RESET_VOUCHER}/pending`, (state) => {
      state.status.voucherDetail = 'pending';
    })
    .addCase(`${RESET_VOUCHER}/fulfilled`, (state) => {
      state.voucherDetail = null;
      state.status.voucherDetail = 'succeeded';
    })
    .addDefaultCase((state) => state);
});

export default voucherReducer;

/*
Author: Nishant Singh
*/

import { Cart } from '~api/cart';
import { createAsyncThunk } from '@reduxjs/toolkit';

// ...........Actions creator
export const SET_VOUCHER = 'SET_VOUCHER';
export const RESET_VOUCHER = 'RESET_VOUCHER';

// ..........actions
export const applyVoucher = createAsyncThunk(SET_VOUCHER, async (payload) => {
  try {
    const res = await Cart.applyVoucherRequest(payload);
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (err) {
    return err?.response?.data;
  }
});

export const cancelVoucher = createAsyncThunk(
  RESET_VOUCHER,
  async (payload) => {
    try {
      const res = await Cart.cancelVoucherRequest(payload);
      if (res.status === 200) {
        return res.data.success;
      }
    } catch (err) {
      return err.response.data;
    }
  },
);

export const getVouchers = (payload) => {
  return async () => {
    try {
      const res = await Cart.vouchersRequest(payload);
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (err) {
      return err.response.data;
    }
  };
};

export const getVouchersCount = (params) => {
  return async () => {
    try {
      const res = await Cart.vouchersCountRequest(params);
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (err) {
      return err.response.data;
    }
  };
};

export const getCategoryVouchers = createAsyncThunk(
  'cart/categoryVouchers',
  async (id) => {
    try {
      const res = await Cart.getVouchersByCategoryIdRequest(id, {
        fields:
          '_id name discount_type free_products_type voucher_info image to_date code',
        sort: '-created_at',
      });
      return res.data.data;
    } catch (err) {
      return err.response.data;
    }
  },
);

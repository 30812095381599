import { createSlice } from '@reduxjs/toolkit';
import {
  getTagsByLevelName,
  WISHLIST_DATA,
  getProductsJustLanded,
  getYouMightAlsoLikeProducts,
  loadMoreYouMightAlsoLikeProducts,
  getTotalYouMightAlsoLikeProducts,
} from '~store/catalog/actions/catalog';

export const { actions, reducer } = createSlice({
  name: 'catalog',
  initialState: {
    tags: [],
    wishlistData: [],
    wishlistSyncData: {},
    justLandedData: [],
    youMightAlsoLikeProducts: [],
    totalYouMightAlsoLikeProducts: '',
    status: {
      tags: 'idle',
      wishlistData: 'idle',
      justLandedData: 'idle',
      youMightAlsoLike: {
        products: 'idle',
        loadMoreProducts: 'idle',
      },
    },
  },
  reducers: {
    SET_WISHLIST_SYNC_DATA(state, action) {
      state.wishlistSyncData = { ...state.wishlistSyncData, ...action.payload };
    },
    SET_JUST_LANDED_DATA(state, action) {
      state.justLandedData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(`${getTagsByLevelName}/pending`, (state, action) => {
        state.status.tags = 'pending';
      })
      .addCase(`${getTagsByLevelName}/fulfilled`, (state, { payload }) => {
        state.tags = payload;
        state.status.tags = 'succeeded';
      })
      .addCase(`${WISHLIST_DATA}/pending`, (state, action) => {
        state.status.wishlistData = 'loading';
      })
      .addCase(`${WISHLIST_DATA}/fulfilled`, (state, { payload }) => {
        state.wishlistData = payload;
        state.status.wishlistData = 'finished';
      })
      .addCase(getProductsJustLanded.pending, (state, action) => {
        state.status.justLandedData = 'loading';
      })
      .addCase(getProductsJustLanded.rejected, (state, action) => {
        state.status.justLandedData = 'error';
      })
      .addCase(getProductsJustLanded.fulfilled, (state, { payload }) => {
        state.justLandedData = payload;
        state.status.justLandedData = 'finished';
      })
      .addCase(getYouMightAlsoLikeProducts.pending, (state, action) => {
        state.status.youMightAlsoLike.products = 'loading';
      })

      .addCase(getYouMightAlsoLikeProducts.fulfilled, (state, { payload }) => {
        state.youMightAlsoLikeProducts = payload;
        state.status.youMightAlsoLike.products = 'finished';
      })
      .addCase(
        getTotalYouMightAlsoLikeProducts.fulfilled,
        (state, { payload }) => {
          state.totalYouMightAlsoLikeProducts = payload;
        },
      )
      .addCase(loadMoreYouMightAlsoLikeProducts.pending, (state, action) => {
        state.status.youMightAlsoLike.loadMoreProducts = 'loading';
      })
      .addCase(loadMoreYouMightAlsoLikeProducts.rejected, (state, action) => {
        state.status.youMightAlsoLike.loadMoreProducts = 'error';
      })
      .addCase(
        loadMoreYouMightAlsoLikeProducts.fulfilled,
        (state, { payload }) => {
          state.youMightAlsoLikeProducts = [
            ...state.youMightAlsoLikeProducts,
            ...payload,
          ];
          state.status.youMightAlsoLike.loadMoreProducts = 'finished';
        },
      )

      .addDefaultCase((state) => state);
  },
});

export const { SET_WISHLIST_SYNC_DATA, SET_JUST_LANDED_DATA } = actions;

export default reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Careers from '~api/careers';

const initialState = {
  careers: [],
  status: {
    careers: 'idle',
    loadMoreProducts: 'idle',
  },
};

export const getCareers = createAsyncThunk(
  'careers/getCareers',
  async (params) => {
    const response = await Careers.getCareersRequest(params);
    return response;
  },
);

// export const getProducts = createAsyncThunk(
//   'careers/getProducts',
//   async (params) => {
//     const response = await Careers.getProductsRequest(params);
//     return response.data;
//   },
// );

const bestSellerSlice = createSlice({
  name: 'careers',
  initialState,
  reducers: {
    // SET_STATE(state, { value, name }) {
    //   state[name] = value;
    // },
    // SET_STATUS_API(state, { value, name }) {
    //   state.status[name] = value;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCareers.pending, (state, action) => {
        state.status.careers = 'loading';
      })
      .addCase(getCareers.rejected, (state, action) => {
        state.status.careers = 'error';
      })
      .addCase(getCareers.fulfilled, (state, { payload }) => {
        state.careers = payload;
        state.status.careers = 'finished';
      });
  },
});

// export const { SET_STATE, SET_STATUS_API } = bestSellerSlice.actions;
export default bestSellerSlice.reducer;

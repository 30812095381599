/*
Author: Nishant Singh
Description: Every reducer name should be name followed by suffix Reducer with camelCase.
*/
import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  CART_PROUDUCT_COUNT,
  CART_DETAIL,
  CART_ADD,
  CART_REORDER,
  CART_RESET,
} from '../actions/cart';

const setCartDetail = createAction('CART_DETAIL');
const setCartProductCount = createAction('CART_PROUDUCT_COUNT');

// ........States
const initialState = {
  cartProductCount: 0,
  cartDetail: {},
  isRedeemPointsApplied: false,
  isRedeemVoucherApplied: false,
  extrabags: [],
  manualVoucher: [],
  freeShippingVoucher: [],
  status: {
    cartProductCount: 'idle',
    cartDetail: 'idle',
    extrabags: 'idle',
    toggleExtrabag: 'idle',
    cartAdd: 'idle',
    cartReorder: 'idle',
    changeAddress: 'idle',
  },
};

const cartReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCartDetail, (state, action) => {
      state.cartDetail = action.payload;
    })
    .addCase(setCartProductCount, (state, action) => {
      state.cartProductCount = action.payload;
    })
    .addCase(`${CART_PROUDUCT_COUNT}/pending`, (state) => {
      state.status.cartProductCount = 'pending';
    })
    .addCase(`${CART_PROUDUCT_COUNT}/fulfilled`, (state, action) => {
      state.cartProductCount = action.payload;
      state.status.cartProductCount = 'succeeded';
    })
    .addCase(`${CART_DETAIL}/pending`, (state) => {
      state.status.cartDetail = 'pending';
    })
    .addCase(`${CART_DETAIL}/fulfilled`, (state, action) => {
      state.isRedeemPointsApplied = !!action.payload?.point_applied;
      state.cartDetail = action.payload;
      if (action.payload?.discount?.applied_vouchers?.length) {
        action?.payload?.discount?.applied_vouchers.forEach((element) => {
          if (element.voucher_type == 'manual') {
            state.manualVoucher = [...state.manualVoucher, element];
          } else if (element.type == 'free_shipping') {
            state.freeShippingVoucher = [...state.freeShippingVoucher, element];
          }
        });
      } else {
        state.manualVoucher = [];
        state.freeShippingVoucher = [];
      }
      state.isRedeemVoucherApplied = Boolean(state.manualVoucher.length);
      state.status.cartDetail = 'succeeded';
    })
    .addCase(`${CART_ADD}/pending`, (state) => {
      state.status.cartAdd = 'pending';
    })
    .addCase(`${CART_ADD}/rejected`, (state) => {
      state.status.cartAdd = 'failed';
    })
    .addCase(`${CART_ADD}/fulfilled`, (state, action) => {
      state.status.cartAdd = 'succeeded';
    })
    .addCase(`${CART_REORDER}/pending`, (state) => {
      state.status.cartReorder = 'pending';
    })
    .addCase(`${CART_REORDER}/rejected`, (state) => {
      state.status.cartReorder = 'failed';
    })
    .addCase(`${CART_REORDER}/fulfilled`, (state, action) => {
      state.status.cartReorder = 'succeeded';
    })
    .addCase(CART_RESET, (state) => {
      state.cartProductCount = 0;
      state.cartDetail = {};
    })
    .addCase('cart/extrabags/pending', (state) => {
      state.status.extrabags = 'pending';
    })
    .addCase('cart/extrabags/fulfilled', (state, action) => {
      state.extrabags = action.payload;
      state.status.extrabags = 'succeeded';
    })
    .addCase('cart/toggleExtrabag/pending', (state) => {
      state.status.toggleExtrabag = 'pending';
    })
    .addCase('cart/toggleExtrabag/fulfilled', (state) => {
      state.status.toggleExtrabag = 'succeeded';
    })
    .addCase('cart/changeAddress/pending', (state) => {
      state.status.changeAddress = 'pending';
    })
    .addCase('cart/changeAddress/fulfilled', (state) => {
      state.status.changeAddress = 'succeeded';
    })
    .addDefaultCase((state) => state);
});

export default cartReducer;
